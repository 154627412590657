<style scoped>
.thankyou-page-wrapper{
  background-color: rgb(246 247 251/70%);
  height: calc(100vh - 291px);
}
.thankyou-page{
  padding: 20px 0;
  background-color: #f1f1f1;
}
.page-title{
  font-size:28px;
  text-align:center;
  color:#04092c;
  margin-bottom:15px;
  font-weight:600
}
.thankyou-page-wrapper .page-content{
  text-align: center;
}
.thankyou-page-wrapper .page-content p{
  font-family: 'Heebo', sans-serif;
  color: #04092c;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 10px;
}
.hero-1{
  position:relative
}
.hero-1.banner-home{
  height:150px;
  background-image: url('~@/../public/images/top-concerts.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hero-1.banner-home img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.hero-1 .hero-content{
  height:100%;
  left:0;
  margin:auto;
  position:absolute;
  right:0;
  text-align:center;
  top:0;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  flex-direction:column;
  padding: 40px 10px 0;
  background-color: rgb(0 0 0 / 24%);
}
.hero-1 .hero-content .hero-title{
  text-align:center;
  font-weight:800;
  color: #fff;
  z-index: 1;
  margin-bottom: 15px;
}
.banner-home:after{
  content:"";
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background:0 0;
  background-color:rgba(0,0,0,.5)
}
.global-search-blur-banner.hero-1 .hero-content .hero-title{
  position: relative;
  z-index: 0;
}
.event-search-location-filter{
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  z-index: 3;
  border-radius: 6px;
  /* overflow: hidden; */
}
.search-wrapper {
  position: relative;
  z-index: 2;
}
.search-wrapper .header-search{
  position:relative
}
.search-wrapper .header-search .search-btn{
  position:absolute;
  left:12px;
  top:13px;
  border:0;
  padding:0;
  color:#fff;
  line-height:14px;
  width:17px;
  height:17px;
  background-image:url('~@/../public/images/header-search-icon.webp');
  background-repeat:no-repeat;
  background-position:center;
  background-size:17px;
  background-color: transparent;
}
.home-location-wrapper{
  position: relative;
  z-index: 2;
}
.banner-home .banner-subtext{
  top: auto!important;;
}
.banner-subtext{
  position: initial;
}
.page-content-paragraph{
  color: white!important;;
}
.email-us{
  color: #fcc900 !important;
  font-size: 16px;
  justify-content: flex-start;
}
@media (min-width: 768px){
  .hero-1 .hero-content .hero-title {
    font-size: 36px;
  }
}
@media (min-width: 1024px){
  .hero-1.banner-home {
    height: 100%;
    background-image: url('~@/../public/images/top-concerts.webp');
  }
  .hero-1 .hero-content .hero-title {
    font-size: 52px;
    line-height: 52px;
  }
}
@media (max-width: 1600px) {
  .thankyou-page-wrapper{
    height: calc(100vh - 290px);
  }
}
@media (max-width: 1440px) {
  /* .thankyou-page-wrapper{ */
    /* height: 100%; */
    /* margin-bottom: 50px; */
  /* } */
}
@media (max-width: 1200px) {
  .search-wrapper .header-search .search-btn{
    left: 10px;
    top: 12px;
  }
}
@media (max-width: 1199px) {
  .search-wrapper .header-search .search-btn{
    width: 15px;
    height: 15px;
    background-size: 15px;
    top: 12px;
    left: 10px;
  }
}
@media (max-width: 1024px) {
  .page-title{
    font-size: 26px;
    margin-bottom: 10px;
  }
  .hero-1.banner-home{
    /* height: 250px; */
    height: 100%;
  }
  .hero-1 .hero-content{
    padding: 20px 10px 0;
  }
}
@media (max-width: 767px) {
  .hero-1.banner-home{
    /* height: 300px; */
    height: 100%;
  }
  .hero-1 .hero-content .hero-title {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 7px;
  }
  .thankyou-page-wrapper .page-content p{
    font-size: 14px;
    margin-bottom: 6px;
  }
  .banner-home .banner-subtext{
    top: 195px;
  }
  .event-search-location-filter{
    flex-direction: column;
    padding: 15px;
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  .search-wrapper{
    margin: 0 0 10px 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  .search-wrapper .header-search .search-btn{
    left: 3px;
  }
  .global-search-blur .home-location-wrapper{
    z-index: 1;
  }
  .home-location-wrapper{
    width: 100%;
  }
}
@media (max-width: 575px) {
  .hero-1.banner-home{
    /* height: 280px; */
    height: 100%;
  }
  .hero-1 .hero-content{
    padding: 30px 15px 0;
  }
  .search-wrapper{
    width: 100%;
    display: flex;
  }
  .search-wrapper .header-search{
    width: 100%;
  }
}
@media (max-width: 480px) {
  .page-title{
    font-size: 24px;
  }
}
@media (max-width: 400px) {
  .hero-1 .hero-content .hero-title{
    font-size: 28px;
    margin: 0px 0 8px 0;
  }
  .event-search-location-filter{
    padding: 10px;
  }
}
</style>
<template>
  <div class="thankyou-page-wrapper">
    <section class="hero-1 banner-home">
      <div class="hero-content">
        <!-- <h1 class="hero-title">Live Events, Lit Prices!</h1> -->
        <h1 class="page-title hero-title">Thank you for Entering!</h1>
        <div class="page-content hero-title">
          <p class="page-content-paragraph">Please check your email inbox for a message from <a href="mailto:noreply@litseats.com" class="email-us">noreply@litseats.com</a> for more information </p>
          <p class="page-content-paragraph">Lit Seats provides the best prices on tickets to 1000s of your favorite live events, search for an event below to start discovering.</p>
        </div>
        <div class="event-search-location-filter">
          <div class="search-wrapper mobile-search-icon-j home-banner-search">
            <div class="hero-search header-search">
              <GlobalSearchAutocomplete/>
              <button v-on:click="searchEvents" class="search-btn" aria-label="home-search-btn" name="home-search-btn" type="submit"></button>
            </div>
          </div>
          <!-- <div class="home-location-wrapper">
            <LocationDropdown @custom-change="customChange" />
          </div> -->
        </div>
        <BannerSubText />
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios'
// import _ from 'lodash'
import GlobalSearchAutocomplete from '../../views/autocomplete/GlobalSearchAutocomplete'
import BannerSubText from './../../components/layouts/BannerSubText.vue'
// import LocationDropdown from '../../components/layouts/header/Location.vue'
// import VueConfetti from 'vue-confetti'

export default {
  name: 'Contact',
  components: {
    GlobalSearchAutocomplete,
    BannerSubText
    // LocationDropdown
  },
  inject: {
    eventCategories: {
      from: 'headerMenuItems'
    }
  },
  beforeCreate () {
    document.title = 'Offer Thank you | ' + process.env.VUE_APP_NAME
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  mounted () {
    // this.start()
    // this.love()

    // setTimeout(() => {
    //   this.stop()
    // }, 4000)
  },
  methods: {
    start: function () {
      this.$confetti.start()
    },

    stop: function () {
      this.$confetti.stop()
    },
    love: function () {
      this.$confetti.update({
        particles: [
          {
            type: 'heart'
          },
          {
            type: 'circle'
          }
        ],
        defaultColors: [
          'red',
          '#fcc900',
          '#f08000'
        ]
      })
    }
  }
}
</script>
