<style scoped>
.cms-inner-page{
  padding:70px 0
}
.page-not-found .page-title,
.page-not-found .page-content{
  text-align: center;
}
.page-not-found .page-title{
  font-size: 52px;
  color: #04092c;
  font-weight: 700;
  margin-bottom: 40px;
}
.page-not-found .page-content p{
  font-size: 18px;
}
.btn-wrapper{
  display: flex;
  justify-content: center;
}
.home-page-link{
  background-color: rgb(240 129 0/20%);
  color: #f08100 !important;
  padding: 8px 12px;
  font-family: 'Heebo', sans-serif;
  font-weight: 600;
  display: block;
  border-radius: 6px;
  font-size: 15px;
  line-height: normal;
  transition: all .3s ease-in-out 0s;
}
.page-not-found{
  height: calc(100vh - 291px);
  display: flex;
  align-items: center;
}
@media (max-width: 1366px) {
  .page-not-found .page-title{
    font-size: 46px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .page-not-found{
    height: calc(100vh - 308px);
  }
}
@media (max-width: 1024px) {
  .cms-inner-page{
    padding:50px 0
  }
  .page-not-found .page-title{
    font-size: 38px;
    margin-bottom: 25px;
  }
  .page-not-found {
    height: calc(100vh - 317px);
  }
}
@media (max-width: 991px) {
  .page-not-found {
    height: calc(100vh - 330px);
  }
}
@media (max-width: 767px) {
  .cms-inner-page{
    padding:35px 0
  }
  .page-not-found .page-title{
    font-size: 32px;
  }
  .page-not-found .page-content p{
    font-size: 16px;
  }
  .page-not-found {
    height: calc(100vh - 319px);
  }
}
@media (max-width: 575px) {
  .page-not-found {
    height: calc(100vh - 326px);
  }
}
@media (max-width: 480px) {
  .page-not-found .page-title{
    font-size: 26px;
    margin-bottom: 20px;
  }
  .page-not-found .page-content p{
    margin-bottom: 10px;
  }
  .page-not-found {
    height: calc(100vh - 324px);
  }
}
@media (max-width: 400px) {
  .page-not-found {
    height: calc(100vh - 370px);
  }
}
</style>
<template>
  <section class="page-not-found cms-inner-page">
    <div class="container">
      <h3 class="page-title">Oops!</h3>
      <div class="page-content">
        <p>404 Page not found</p>
        <p>We can't seem to find the page you're looking for.</p>
        <div class="btn-wrapper">
          <router-link :to="`/`" class="home-page-link">Go to Homepage</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Contact',
  components: { },
  inject: {
    eventCategories: {
      from: 'headerMenuItems'
    }
  },
  beforeCreate () {
    document.title = '404 | ' + process.env.VUE_APP_NAME
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  }
}
</script>
